import {marked} from "marked";
import css from "./widget.css";
import {widgetHTML} from "./widgetHtmlString";
import {autoUpdate, computePosition, flip, shift} from "@floating-ui/dom";

const APP = 'LangChat'
const LANGCHAT_ID = "langchat-web-sdk";
const LANGCHAT_BTN_OPEN = "langchat-button-open";
const LANGCHAT_BTN_CLOSE = "langchat-button-close";
const WIDGET_BACKDROP_ID = "langchat-chat-widget__backdrop";
const WIDGET_CONTAINER_ID = "langchat-chat-widget__container";
const WIDGET_MESSAGES_HISTORY_CONTAINER_ID = "langchat-chat-widget__messages_history";
const WIDGET_THINKING_BUBBLE_ID = "langchat-chat-widget__thinking_bubble";

export type WidgetConfig = {
    element: Element,
    url: string;
    apiKey: string;
    threadId: string | null;
    user: Record<any, any>;
    greetingMessage: string | null;
    disableErrorAlert: boolean;
};

const renderer = new marked.Renderer();
const linkRenderer = renderer.link;
// To open links in a new tab
renderer.link = (href, title, text) => {
    const parsed = linkRenderer.call(renderer, href, title, text);
    return parsed.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
};

const config: WidgetConfig = {
    element: null,
    url: "",
    apiKey: "",
    threadId: null,
    user: {},
    greetingMessage: null,
    disableErrorAlert: false,
    ...(window as any).langchatChatWidget?.config,
};

let cleanup = () => {
};

async function init() {
    let langchatElement: Element | null = null;
    if (config.element == null) {
        langchatElement = document.getElementById(LANGCHAT_ID)
        if (langchatElement == null) {
            langchatElement = document.createElement("div");
            langchatElement.id = LANGCHAT_ID;
            document.body.appendChild(langchatElement)
        }
    } else {
        langchatElement = config.element;
    }
    langchatElement!.innerHTML = widgetHTML;

    const styleElement = document.createElement("style");
    styleElement.innerHTML = css;

    document.head.insertBefore(styleElement, document.head.firstChild);

    // Slight delay to allow DOMContent to be fully loaded
    // (particularly for the button to be available in the `if (config.openOnLoad)` block below).
    await new Promise((resolve) => setTimeout(resolve, 500));

    document.getElementById(LANGCHAT_BTN_OPEN)?.addEventListener("click", open);
    document.getElementById(LANGCHAT_BTN_CLOSE)?.addEventListener("click", close);

    // if (config.openOnLoad) {
    //     const target = document.querySelector(
    //         "[data-langchat-chat-widget-button]"
    //     );
    //     open({target} as Event);
    // }
}

// window.addEventListener("load", init);

const messagesHistory = document.createElement("div");
messagesHistory.id = WIDGET_MESSAGES_HISTORY_CONTAINER_ID;

const optionalBackdrop = document.createElement("div");
optionalBackdrop.id = WIDGET_BACKDROP_ID;

const thinkingBubble = document.createElement("div");
thinkingBubble.id = WIDGET_THINKING_BUBBLE_ID;
thinkingBubble.innerHTML = `
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
  `;

function open(e: Event) {
    document.getElementById(LANGCHAT_BTN_OPEN)!.style.display = 'none'
    document.getElementById(LANGCHAT_BTN_CLOSE)!.style.display = 'block'
    const containerElement = document.getElementById(WIDGET_CONTAINER_ID)!
    containerElement!.style.display = 'block'

    // const chatbotHeaderTitleText = document.createElement("span");
    // chatbotHeaderTitleText.id = "langchat-chat-widget__title_text";
    // chatbotHeaderTitleText.textContent = APP;
    const chatbotHeaderTitle = document.getElementById(
        "langchat-chat-widget__title_text"
    )!;
    chatbotHeaderTitle.innerText = 'LangChat聊天助手';

    document.getElementById('langchat-chat-widget__copyright')!.innerText = APP

    const chatbotBody = document.getElementById("langchat-chat-widget__body")!;
    chatbotBody.prepend(messagesHistory);
    if (config.greetingMessage && messagesHistory.children.length === 0) {
        createNewMessageEntry(config.greetingMessage, Date.now(), "system");
    }

    const target = document.getElementById('langchat-chat-widget__btn')!;
    cleanup = autoUpdate(target, containerElement, () => {
        computePosition(target, containerElement, {
            placement: "top-start",
            middleware: [flip(), shift({crossAxis: true, padding: 8})],
            strategy: "fixed",
        }).then(({x, y}) => {
            console.log(x,y)
            Object.assign(containerElement.style, {
                left: `${x - 350}px`,
                top: `${y - 10}px`,
            });
        });
    });

    document
        .getElementById("langchat-chat-widget__form")!
        .addEventListener("submit", submit);
}

function close() {
    document.getElementById(LANGCHAT_BTN_OPEN)!.style.display = 'block'
    document.getElementById(LANGCHAT_BTN_CLOSE)!.style.display = 'none'
    optionalBackdrop.remove();

    const containerElement = document.getElementById(WIDGET_CONTAINER_ID)
    containerElement!.style.display = 'none'

    cleanup();
    cleanup = () => {
    };
}

async function createNewMessageEntry(
    message: string,
    timestamp: number,
    from: "system" | "user"
) {
    const messageElement = document.createElement("div");
    messageElement.classList.add("langchat-chat-widget__message");
    messageElement.classList.add(`langchat-chat-widget__message--${from}`);
    messageElement.id = `langchat-chat-widget__message--${from}--${timestamp}`;

    const messageText = document.createElement("p");
    messageText.innerHTML = await marked(message, {renderer});
    messageElement.appendChild(messageText);

    const messageTimestamp = document.createElement("p");
    messageTimestamp.classList.add("langchat-chat-widget__message-timestamp");
    messageTimestamp.textContent =
        ("0" + new Date(timestamp).getHours()).slice(-2) + // Hours (padded with 0 if needed)
        ":" +
        ("0" + new Date(timestamp).getMinutes()).slice(-2); // Minutes (padded with 0 if needed)
    messageElement.appendChild(messageTimestamp);

    messagesHistory.prepend(messageElement);
}

const handleStandardResponse = async (res: Response) => {
    if (res.ok) {
        const {
            message: responseMessage,
            threadId: responseThreadId,
        }: {
            message: string | undefined;
            threadId: string | undefined;
        } = await res.json();

        if (typeof responseThreadId !== "string") {
            console.error("LangChat Chat Widget: Server error", res);
            if (!config.disableErrorAlert)
                alert(
                    `Received an OK response but "threadId" was of incompatible type (expected 'string', received '${typeof responseThreadId}'). Please make sure the API response is configured correctly.

You can learn more here: https://github.com/rowyio/langchat-chat-widget?tab=readme-ov-file#connecting-the-widget-to-your-langchat-workflow`
                );
            return;
        }

        if (typeof responseMessage !== "string") {
            console.error("LangChat Chat Widget: Server error", res);
            if (!config.disableErrorAlert)
                alert(
                    `Received an OK response but "message" was of incompatible type (expected 'string', received '${typeof responseMessage}'). Please make sure the API response is configured correctly.

You can learn more here: https://github.com/rowyio/langchat-chat-widget?tab=readme-ov-file#connecting-the-widget-to-your-langchat-workflow`
                );
            return;
        }

        if (!responseMessage && responseMessage !== "") {
            console.error("LangChat Chat Widget: Server error", res);
            if (!config.disableErrorAlert)
                alert(
                    `Received an OK response but no message was found. Please make sure the API response is configured correctly. You can learn more here:\n\nhttps://github.com/rowyio/langchat-chat-widget?tab=readme-ov-file#connecting-the-widget-to-your-langchat-workflow`
                );
            return;
        }

        await createNewMessageEntry(responseMessage, Date.now(), "system");
        config.threadId = config.threadId ?? responseThreadId ?? null;
    } else {
        console.error("LangChat Chat Widget: Server error", res);
        if (!config.disableErrorAlert)
            alert(`Could not send message: ${res.statusText}`);
    }
};

async function streamResponseToMessageEntry(
    message: string,
    timestamp: number,
    from: "system" | "user"
) {
    const existingMessageElement = messagesHistory.querySelector(
        `#langchat-chat-widget__message--${from}--${timestamp}`
    );
    if (existingMessageElement) {
        // If the message element already exists, update the text
        const messageText = existingMessageElement.querySelector("p")!;
        messageText.innerHTML = await marked(message, {renderer});
        return;
    } else {
        // If the message element doesn't exist yet, create a new one
        await createNewMessageEntry(message, timestamp, from);
    }
}

const handleStreamedResponse = async (res: Response) => {
    if (!res.body) {
        console.error("LangChat Chat Widget: Streamed response has no body", res);
        if (!config.disableErrorAlert)
            alert(
                `Received a streamed response but no body was found. Please make sure the API response is configured correctly.`
            );
        return;
    }

    const threadIdFromHeader = res.headers.get("x-thread-id");

    let responseMessage = "";
    let responseThreadId = "";
    let responseMessageComplete = false;
    let ts = Date.now();
    const reader = res.body.getReader();

    while (true) {
        const {value, done} = await reader.read();
        if (done || value === undefined) {
            break;
        }
        const decoded = new TextDecoder().decode(value, {stream: true});

        const messages = decoded.split('\n');

        for (const item of messages) {
            const message = item
            if (message.startsWith('data:{') || message.startsWith('{')) {
                let data = '';
                if (message.startsWith('data:{')) {
                    data = message.slice(5).trim();
                }
                if (message.startsWith('{')) {
                    data = message.trim();
                }

                try {
                    const jsonData = JSON.parse(data);
                    const choice = jsonData.choices[0];

                    if (choice.finishReason === 'STOP') {
                        responseMessageComplete = true;
                        return;
                    }
                    const content = choice.delta.content;
                    responseMessage += content;
                } catch (error) {
                    responseMessageComplete = true;
                    console.error('Failed to parse JSON:', error);
                }
            }
        }

        await streamResponseToMessageEntry(responseMessage, ts, "system");
    }

    config.threadId =
        config.threadId ??
        threadIdFromHeader ?? // If the threadId isn't set, use the one from the header
        (responseThreadId !== "" ? responseThreadId : null); // If the threadId isn't set and one isn't included in the header, use the one from the response
};

async function submit(e: Event) {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    if (!config.url) {
        console.error("LangChat Chat Widget: No URL provided");
        if (!config.disableErrorAlert)
            alert("Could not send chat message: No URL provided");
        return;
    }

    const submitElement = document.getElementById(
        "langchat-chat-widget__submit"
    )!;
    submitElement.setAttribute("disabled", "");

    const requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");
    requestHeaders.append("Accept", "text/event-stream");
    requestHeaders.append("Authorization", `Bearer ${config.apiKey}`);

    const message = (target.elements as any).message.value
    const data = {
        ...config.user,
        messages: [{
            role: 'user',
            content: message,
        }],
        threadId: config.threadId,
        timestamp: Date.now(),
    };

    await createNewMessageEntry(message, data.timestamp, "user");
    target.reset();
    messagesHistory.prepend(thinkingBubble);

    try {
        let response = await fetch(config.url, {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(data),
        });
        thinkingBubble.remove();

        // if (config.responseIsAStream) {
        //     await handleStreamedResponse(response);
        // } else {
        //     await handleStandardResponse(response);
        // }
        await handleStreamedResponse(response);
    } catch (e: any) {
        thinkingBubble.remove();
        console.error("LangChat Chat Widget:", e);
        if (!config.disableErrorAlert) {
            alert(`Could not send message: ${e.message}`);
        }
    }

    submitElement.removeAttribute("disabled");
    return false;
}

const langchatChatWidget = {open, close, config, init};
(window as any).langchatChatWidget = langchatChatWidget;
declare global {
    interface Window {
        langchatChatWidget: typeof langchatChatWidget;
    }
}

export default langchatChatWidget;
